import _crypto from "crypto";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var r;

exports = function rand(len) {
  if (!r) r = new Rand(null);
  return r.generate(len);
};

function Rand(rand) {
  (this || _global).rand = rand;
}

exports.Rand = Rand;

Rand.prototype.generate = function generate(len) {
  return this._rand(len);
}; // Emulate crypto API using randy


Rand.prototype._rand = function _rand(n) {
  if ((this || _global).rand.getBytes) return (this || _global).rand.getBytes(n);
  var res = new Uint8Array(n);

  for (var i = 0; i < res.length; i++) res[i] = (this || _global).rand.getByte();

  return res;
};

if (typeof self === "object") {
  if (self.crypto && self.crypto.getRandomValues) {
    // Modern browsers
    Rand.prototype._rand = function _rand(n) {
      var arr = new Uint8Array(n);
      self.crypto.getRandomValues(arr);
      return arr;
    };
  } else if (self.msCrypto && self.msCrypto.getRandomValues) {
    // IE
    Rand.prototype._rand = function _rand(n) {
      var arr = new Uint8Array(n);
      self.msCrypto.getRandomValues(arr);
      return arr;
    }; // Safari's WebWorkers do not have `crypto`

  } else if (typeof window === "object") {
    // Old junk
    Rand.prototype._rand = function () {
      throw new Error("Not implemented yet");
    };
  }
} else {
  // Node.js or Web worker with no crypto support
  try {
    var crypto = _crypto;
    if (typeof crypto.randomBytes !== "function") throw new Error("Not supported");

    Rand.prototype._rand = function _rand(n) {
      return crypto.randomBytes(n);
    };
  } catch (e) {}
}

export default exports;
const _Rand = exports.Rand;
export { _Rand as Rand };